export const experienceData = [
    {
        id: 1,
        company: 'Self Employed',
        jobtitle: 'Full Stack Developer',
        startYear: '2022',
        endYear: ''
    },
    // {
    //     id: 2,
    //     company: 'Vehement Capital Partners',
    //     jobtitle: 'Backend Developer',
    //     startYear: '2019',
    //     endYear: '2020'
    // },
    // {
    //     id: 3,
    //     company: 'Wonka Industries',
    //     jobtitle: 'Marketing',
    //     startYear: '2020',
    //     endYear: 'Present'
    // },
]