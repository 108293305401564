import resume from '../assets/pdf/resume.pdf'
import image1 from '../assets/png/personal.jpg'

export const headerData = {
    name: 'Edvinas Šataitis',
    title: "Developer",
    desciption:"I'm a Junior Full-Stack Developer with little experience so far but with a very big determination to improve. I am looking for a company which would believe in me and let me become a useful part of the team.   ",
    image: image1,
    resumePdf: resume
}
