export const educationData = [
    {
        id: 1,
        institution: 'Kaunas V. Kuprevičius Secondary School',
        course: 'Higher Secondary Education',
        startYear: '1984',
        endYear: '1995'
    },
    {
        id: 2,
        institution: 'Kaunas Trade and Business School',
        course: 'Vocational',
        startYear: '1995',
        endYear: '1997'
    },
    {
        id: 3,
        institution: 'Baltic Institute of Technology',
        course: 'Junior PHP Programmer',
        startYear: '2022',
        endYear: '2022'
    },
]